/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'editor';
@import 'bootstrap/scss/bootstrap';
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/solid';
@import '@fortawesome/fontawesome-pro/scss/brands';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/light';
@import 'prosemirror-view/style/prosemirror.css';
@import 'prosemirror-menu/style/menu.css';
@import 'prosemirror-gapcursor/style/gapcursor.css';
@import 'prosemirror-example-setup/style/style.css';
@import 'react-datepicker/dist/react-datepicker.css';

/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 3.5rem tall */

$env-info-blue: #1793cc;
$asvg-blue: #00abff;
$asvg-red: #bc1413;
$border-color: #dee2e6;
$env-check: #6eb657;
$ev-background: #f7f8fa;
$ev-disabled-color: #d1cccc;

/* Overwrite Bootstrap*/

.customer-remark {
  h3 {
    font-size: 1rem;
  }

  pre {
    white-space: break-spaces;
    word-break: break-word;
    max-width: 20rem;
  }
}

.form-select-sm.badge {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
}

/* ----- */
a,
.btn-link {
  color: #0d6efd;
  text-decoration: none !important;
}

.text-env-info {
  color: $env-info-blue !important;
}

.background-asvg-blue {
  background: $asvg-blue;
  color: white;
}

.background-env-grey {
  background-color: $ev-background;
}

.text-asvg-red {
  color: $asvg-red !important;
}

.env-error {
  border-color: red !important;
  color: red !important;
}

.bg-env-orange {
  background: orange;
  border-color: orange;
  color: white;
}

.env-field {
  @extend .form-control, .form-control-sm;
}

.first-line-bold {
  &::first-line {
    font-weight: bold;
  }
}

.padding-end-30-px {
  padding-right: 30px !important;
}

.fontawesome-family {
  font-family: sans-serif, 'Font Awesome 6 Pro' !important;
}

.react-datepicker-popper {
  @extend .react-datepicker-popper;
  z-index: 15;
}

.env-text-radio-save-button {
  @extend .btn, .btn-sm, .btn-outline-success;
  border-radius: 0;

  &:focus {
    box-shadow: none;
  }
}

.env-text-radio-button-base {
  @extend .btn, .btn-sm, .border, .px-3;
  border-radius: 0 !important;
  color: black;
  background: white;
  font-size: 0.85rem;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    @extend .bg-primary, .border-primary;
    color: white;
  }

  &.sm {
    @extend .py-0, .px-2;
    font-size: 12px !important;
  }
}

.env-text-radio-delete-button {
  @extend .btn, .btn-sm;

  &.delete-on {
    @extend .btn-danger, .border-danger;

    &:focus {
      box-shadow: none;
    }
  }

  &.delete-off {
    @extend .btn-outline-danger;

    &:focus {
      box-shadow: none;
    }
  }

  border-radius: 0;
}

.env-text-radio-button {
  @extend .env-text-radio-button-base;

  &.active {
    @extend .bg-primary, .border-primary;
    color: white;
  }

  &.delete {
    color: $danger;
    border-color: $danger !important;
    &:hover {
      @extend .bg-danger;
      color: white;
    }
  }

  &.delete-active {
    @extend .btn-danger;
  }

  &.outline-success {
    color: $success;
    border-color: $success !important;

    &:hover {
      @extend .bg-success;
      color: white;
    }
  }

  &.success {
    background-color: $success !important;
    border-color: $success !important;
    color: white !important;
  }

  &.info {
    @extend .text-dark;
    background-color: $info !important;
    border-color: $info !important;
  }

  &.outline-info {
    color: $info;
    border-color: $info !important;

    &:hover {
      @extend .bg-info, .text-dark;
    }
  }

  .ev-tab-radio-main-calc {
    &:hover {
      color: $warning !important;
    }
  }
}

.env-text-radio-button-multiple {
  @extend .env-text-radio-button-base;
  display: flex;
  align-items: baseline;

  &.active {
    @extend .bg-primary, .border-primary;
    color: white;
  }

  &:focus {
    box-shadow: none;
  }
}

.env-text-radio-button-group {
  .env-text-radio-button-base:first-child {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .env-text-radio-button-base:last-child {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}

.env-button-link {
  @extend .btn, .btn-link, .p-0;
  font-size: inherit;

  &:focus {
    box-shadow: none;
  }
}

.env-text-button {
  @extend .btn, .p-0, .border-0;

  &:focus {
    box-shadow: none;
  }
}

.env-button-info {
  background: $env-info-blue;
  color: white;
}

.env-button-select {
  @extend .btn-primary, .w-100, .p-2, .text-start, .border;
  min-height: 40px;
  background-color: transparent;
}

.env-button-select:active,
.env-button-select.active {
  @extend .border-primary, .bg-primary, .bg-opacity-10;
}

.env-div-select {
  @extend .border, .w-100, .p-2, .d-flex, .align-items-center;
  min-height: 40px;
}

.env-sticky-bottom-info {
  display: flex;
  position: sticky;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  align-items: center;
}

.env-unsaved-changes-footer {
  @extend .env-sticky-bottom-info;
  flex-direction: column;
}

.env-sticky-save {
  @extend .env-sticky-bottom-info, .alert, .alert-warning, .p-2, .shadow-sm;
  justify-content: space-between;
}

.env-bottom-error-info-wrapper {
  display: flex;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 70vw;
  left: 15vw;
  @extend .flex-column;

  .env-bottom-error-info {
    align-items: center;
    @extend .alert, .alert-danger, .p-2, .mx-5, .mb-3;
  }

  .env-bottom-success-info {
    align-items: center;
    @extend .alert, .alert-success, .p-2, .mx-5, .mb-3;
  }
}

body {
  font-size: 0.9rem;
  background-color: $ev-background !important;
  min-height: 100vh;
}

.main-wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;

  .main-content {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 60.5px);
    min-width: 0;
    flex-direction: column;
  }
}

tr {
  &.inactive {
    background: #e9e7e7;
    color: #b9b9b9;
  }
}

.account-settings {
  display: flex;
  align-items: center;
  position: fixed;
  top: 8px;
  right: 15px;
  z-index: 1000;

  .dropdown-toggle::after {
    display: none;
  }

  .customer-active {
    @extend .alert-success;
  }

  .customer-inactive {
    @extend .alert-danger;
  }

  .separator {
    cursor: initial;
    padding-left: 1rem;
    padding-right: 1rem;
    color: gray;
  }

  .info-text {
    @extend .separator;
    font-size: 12px;
  }

  li {
    @extend .pointer;
    padding: 0;
    white-space: normal;

    a {
      padding: 0.25rem 1rem;
      width: 100%;
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: inherit !important;
  }

  button {
    @extend .background-env-grey;
    color: $asvg-blue;
    margin-left: 15px;
    padding: 0;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    font-size: 18px;
    border: none;
    overflow: hidden;
    border-width: 0;
  }
}

.env-tab-button {
  @extend .me-2;
  cursor: pointer;
  text-decoration: none;

  &.active {
    color: $env-info-blue;
  }
}

.env-number-input-button {
  @extend .btn-outline-secondary, .h-auto, .px-2;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.disabled {
    @extend .disabled;
    cursor: inherit;
  }
}

.logout-submit {
  width: 100%;
  padding: 0.25rem 1rem;
  background: transparent;
  border: none;
}

.side-navbar-link {
  @extend .text-center;
  color: white;
}

.env-tab {
  @extend .rounded, .border;
  background: #dedddd;
  height: 100%;
}

.env-tab-border {
  @extend .shadow-sm, .rounded, .border;
}

.env-tab-base {
  @extend .w-100, .bg-white, .env-tab-border;
}

.asvg-success {
  @extend .text-success;
  background: rgba(225, 239, 204, var(--bs-bg-opacity));
}

.env-tab-content {
  @extend .env-tab-base;
}

.env-table-vertical {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  overflow: auto;

  .env-table-column-base {
    font-size: 11px;
    border-top: 1px solid lightgray;
    animation: 0.2s ease-out fadein;
    transition:
      width,
      height 0.2s ease-out;
    background: white;
  }

  .env-table-column-lg {
    @extend .env-table-column-base;
    @extend .py-3;
    @extend .px-1;
  }

  .env-table-column {
    @extend .env-table-column-base;
    @extend .p-1;
  }

  .env-table-column-sum {
    @extend .env-table-column;
    @extend .text-center;
    background: #e6f3f9;
    position: sticky;
    right: 0;
    z-index: 5;
  }

  .env-table-column-cell {
    @extend .env-table-column;
    display: flex;
    justify-content: center;

    &.error {
      background: #fac7c7;
    }

    &.warning {
      background: #faec9e;
    }

    &.stripe {
      background: #efefef;
    }
  }

  .env-table-column-cell-lg {
    @extend .env-table-column-lg;
    display: flex;
    justify-content: center;

    &.error {
      background: #fac7c7;
    }

    &.warning {
      background: #faec9e;
    }

    &.stripe {
      background: #efefef;
    }
  }

  @keyframes fadein {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .env-table-header {
    @extend .env-table-column;
    font-weight: bold;
    font-size: 12px;
    min-height: 200px;

    &.error {
      background: #fac7c7;
    }

    &.warning {
      background: #faec9e;
    }

    &.stripe {
      background: #efefef;
    }
  }

  .env-table-header-sum {
    @extend .env-table-header;
    @extend .text-center;
    background: #e6f3f9;
    position: sticky;
    right: 0;
    z-index: 5;
  }

  .table-head {
    position: sticky;
    left: 0;
    z-index: 5;
  }
}

.calc-navbar {
  @extend .background-env-grey, .shadow;
  height: 100vh !important;
  position: fixed;
  right: 0;
  top: 0;
  border-radius: 4px;
  z-index: 1001;

  .calc-navbar-content {
    overflow: auto;
    height: 100vh !important;

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }
}

.pointer {
  cursor: pointer;
}

.green {
  color: $asvg-green;
}

.search-select {
  background: white;
  border: 1px solid gray;
  z-index: 99999;
  position: absolute;
  border-radius: 5px;
}

.selected {
  @extend .green;
  font-weight: bold;
}

.collapse-visible {
  display: flex;
}

.row-sm {
  margin-left: -5px;
  margin-right: -5px;

  > [class*='col-'] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.env-toggle-button {
  @extend .btn, .btn-link, .p-0;
  font-size: 20px;

  &:focus {
    box-shadow: none;
  }
}

/*
 * Header
 */

/*
 * Typography
 */
h1 {
  padding-bottom: 9px;
  margin-bottom: 20px;
}

h1,
h2,
h3 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.1rem;
}

/*
 * Sidebar
 */
.sidebar {
  position: fixed;
  top: 51px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid #eee;
}

.sidebar .nav {
  margin-bottom: 20px;
}

.sidebar .nav-item {
  width: 100%;
}

.sidebar .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar .nav-link {
  border-radius: 0;
}

/*
 * Dashboard
 */

/* Placeholders */
.placeholders {
  padding-bottom: 3rem;
}

.placeholder img {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/*
 * Form Handling
 */
.form-control {
  &-file {
    border: none;
  }
}

/*
 * Datepicker
 */
.cursor-picker {
  cursor: pointer;
}

.element-cursor-picker {
  cursor: pointer;
}

.ev-date-picker-menu-placement {
  padding: 0;
}

.ev-date-picker-table {
  width: 354px;
  height: 285px;
  margin-bottom: 0;
  background-color: white;

  th {
    font-size: 0.6rem !important;
    padding: 0 0.1px 0.1px;
  }

  td {
    padding: 0.1px;

    button {
      padding: 0.1px;
    }
  }
}

.ev-date-picker-title {
}

.ev-date-picker-table-icon {
  width: 28px;
}

.ev-date-picker-view {
  .ev-day {
    .active {
      background-color: rgb(2, 117, 216);
      color: white;
    }

    .btn[disabled] {
      border: 0 !important;
    }
  }

  .ev-month {
    .btn.disabled {
      border: 0 !important;
    }
  }

  .ev-year {
    .btn.disabled {
      border: 0 !important;
    }
  }
}

.full-width {
  width: 100%;
}

.table {
  margin: 0;

  thead tr.padding-top th {
    padding-top: 5px;
  }

  thead th {
    font-size: 0.8rem;
    border-top: none;
    padding: 0 5px 4px 5px;
    border-color: $border-color;
  }

  tbody,
  thead {
    border: unset !important;
  }

  tbody.last-child-pagination tr:last-child td {
    border-top: none;

    nav {
      @extend .mt-4;
    }
  }

  .dropdown-menu {
    background-color: #fff;
  }

  .table-filter-row {
    @extend .bg-light;

    td {
      border-width: 0;
    }
  }

  tbody {
    tr {
      td {
        padding: 6px 5px;
        border-color: $border-color;

        .btn {
          vertical-align: top;
          padding: 0 3px;
        }

        .btn-link i {
          font-size: 20px;
          position: relative;
          top: 0;
          line-height: 22px;
        }

        .btn-link i.xl {
          font-size: 24px;
        }

        .btn-sm {
          padding: 2px 7px;
        }

        input[type='checkbox'] {
          margin-top: 4px;
        }
      }

      &.hover:hover td {
        background-color: #f9f9f9;
      }

      &:last-child td {
        border: unset;
      }
    }
  }
}

tr.row-disable {
  td {
    cursor: default !important;
  }
}

.table-click {
  tr {
    td {
      cursor: pointer;
    }

    &:hover td {
      background-color: #f9f9f9;
    }
  }
}

/** grid layout in searches */

.card {
  width: 100%;
  margin-bottom: 3px;

  &-body {
    padding: 10px;
    font-size: 0.8rem;

    p {
      margin-bottom: 0.2rem;
    }
  }

  &-title {
    margin-bottom: 0.4rem;
  }

  &-link {
    padding: 0.4rem 0 0 0;
  }

  &-head {
    padding: 10px;

    h5 {
      margin: 0;
      padding: 5px 0;
    }
  }

  &-inner {
    border-width: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-bottom: 0;

    .card-body {
      padding: 10px 0;

      .btn {
        font-size: 1rem;
        font-weight: bold;
        padding: 0;
        display: block;
        text-decoration: underline;
        background: none;
        color: #155724;

        .star {
          color: #fed600;
        }
      }

      .red,
      .red .star {
        color: #721c24;
      }
    }
  }
}

.card-pointer {
  cursor: pointer;
}

.card-selected {
  border: 3px $asvg-blue solid;
}

.card-red {
  background-color: #801901;
  color: white;
  text-align: center;
  cursor: pointer;
}

.card-yellow {
  background-color: #ffc400;
  color: white;
  text-align: center;
  cursor: pointer;
}

.card-green {
  background-color: #d8d200;
  color: white;
  text-align: center;
  cursor: pointer;
}

.card-icon {
  text-align: center;

  .fa {
    display: block;
    font-size: 100px;
    padding-bottom: 10px;
    color: $asvg-red;
  }
}

.sidebar-grid {
  grid-area: sidebar;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-row-align: start;

  @media all and (-ms-high-contrast: none) {
    z-index: 1000;
  }
}

.content-grid {
  grid-area: main;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}

/* autoprefixer: off **/
.loading-spinner {
  position: fixed;
  left: 45%;
  top: 4rem;
}

.excel-icon {
  color: #469866;
}

.ev-card-columns {
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }

  @include media-breakpoint-only(lg) {
    column-count: 3;
  }

  @include media-breakpoint-only(md) {
    column-count: 2;
  }

  @include media-breakpoint-only(sm) {
    column-count: 2;
  }

  @include media-breakpoint-only(xs) {
    column-count: 1;
  }

  .card {
    @include media-breakpoint-only(xl) {
      width: 454.2px;
    }

    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    display: inline-block;
  }
}

.news {
  h3 {
    font-size: 1.24rem !important;
  }

  h4 {
    font-size: 1.15rem !important;
  }
}

.popover {
  max-width: 400px;
}

.popover-body-xl {
  width: 400px;
}

.popover-body-xxl {
  width: 600px;
  max-width: 600px;
}

.active-comment {
  color: rgb(2, 117, 216);
  font-size: 1.5em;
  padding-top: 6px;
}

.custom-control-padding {
  .custom-control {
    padding-top: 6px;
  }
}

.area-height-300 {
  height: 300px !important;
}

.ev-fa-half-x {
  font-size: 1em;
}

.ev-fa-card-head {
  padding: 5px 10px;
}

.envisia-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline !important;
    color: #0056b3;
  }
}

.moveOffScreen {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}

.env-modal-background {
  position: fixed;
  background-color: darkgray;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  top: 0;
}

.env-offer-link {
  color: inherit;
  &.active {
    color: $env-info-blue !important;
  }
}

.env-offer-table-pos {
  width: 30px;
}

.env-project-offer {
  tbody {
    tr {
      &:hover {
        background: #f7f5f5;
      }
    }
  }
}

.offer-card {
  @extend .card, .shadow, .d-flex, .mb-4;
  &:last-child {
    @extend .mb-0;
  }
}

.envisiasite {
  background: #f6f6f6;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .errorsite {
    width: 100%;
    text-align: center;
    margin-top: 13%;
  }

  .error-img {
    display: inline-block;
    font-size: 90px;
    vertical-align: top;
    height: 100px;
    margin-bottom: 30px;
  }

  .error-img i {
    vertical-align: top;
    margin-top: 1px;
  }

  .error-status {
    display: inline-block;
    text-align: left;
    margin-left: 40px;
    vertical-align: top;
    margin-bottom: 30px;
  }

  .error-status h2 {
    font-size: 25px !important;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
  }

  .error-status h1 {
    font-size: 60px !important;
    font-weight: 300;
    margin: 0;
  }

  .error-message {
    display: block;
    line-height: 40px;
    padding: 0 10px;
  }

  .error-link {
    display: block;
    margin-top: 40px;
  }

  .error-link .btn-lg {
    margin: 0 5px;
    padding: 8px 16px;
  }

  .error-link a i {
    margin-right: 10px;
  }

  .footer {
    position: fixed;
    bottom: 5px;
    width: 100%;
    text-align: center;
  }

  .footer a {
    color: #333 !important;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.bg-danger-light {
  background: #f8d7da;
}

.env-due-button {
  &:disabled {
    border: none !important;
    opacity: 1 !important;
    &.bg-danger {
      @extend .text-white;
    }
    &.bg-warning {
      @extend .text-dark;
    }
  }
}

.env-masonary-wrapper {
  @extend .d-flex, .flex-row, .flex-wrap;

  .env-masonary-card {
    @extend .flex-grow-1;
    width: 49% !important;
  }
}

.badge {
  @extend .badge;

  &.bg-gray {
    background-color: #f0f0f0;
    border-color: #eaeaea;
    color: inherit !important;

    &.close {
      color: #878787 !important;
    }
  }
}

.bg-env-info-blue {
  background-color: $env-info-blue !important;
  color: white !important;
}

.modal {
  padding-right: 0px !important;
  .modal-content {
    @extend .shadow;
  }
  .xxl {
    max-width: none !important;
    width: 90% !important;
  }
  .md {
    max-width: none !important;
    width: 500px !important;
  }
}

.btn-env-gray {
  background-color: #f2f2f2;
  border: 1px solid #cacaca;
  border-radius: 0;
}

.text-env-check {
  color: $env-check !important;
}

.ev-top-nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: $asvg-blue;
}

.react-datepicker__year-wrapper {
  max-width: 204px;
}

.text-ev-disabled {
  color: $ev-disabled-color !important;
}

.bg-asvg {
  background-color: $asvg-blue !important;
}

.dashboard-tile {
  width: 11rem !important;
  height: 7rem !important;
  margin: 5px;
  padding: 0;

  a {
    text-decoration: none;
    color: black;
    width: 100%;
    height: 100%;
  }

  button {
    padding: 0 !important;
  }

  &:hover {
    background: #f9f9f9;
  }
}

.small-dashboard-tile {
  width: 145px !important;
  height: 80px !important;
  margin: 4px;

  a {
    text-decoration: none;
    color: black;
    width: 100%;
    height: 100%;
  }

  button {
    padding: 0 !important;
  }

  &:hover {
    background: #f9f9f9;
  }
}

.dashboard-tile-body {
  display: flex;
  justify-content: center;
  height: 100%;

  div {
    justify-content: center;
    i {
      font-size: 40px;
    }
  }
  span {
    font-weight: bold;
    text-align: center;
  }
}

.small-dashboard-tile-body {
  display: flex;
  justify-content: center;
  height: 100%;

  div {
    justify-content: center;
    i {
      font-size: 30px;
    }
  }
  span {
    font-weight: bold;
    text-align: center;
  }
}

.ev-button-white {
  @extend .btn, .btn-light, .border, .bg-white;
  &:hover {
    @extend .bg-light;
  }
}
